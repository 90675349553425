/**
 * Copyright 2023 LiveKit, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useRoomContext,
  useTracks,
} from '@livekit/components-react';
import EgressHelper from '@livekit/egress-sdk';
import { ConnectionState, RoomEvent, Track } from 'livekit-client';
import { ReactElement, useEffect, useState } from 'react';
import SingleSpeakerLayout from './SingleSpeakerLayout';
import SpeakerLayout from './SpeakerLayout';
import Timer from './Timer';
import { CustomGridLayout } from './SideBySideLayout';
import ManualFocusSpeaker from './ManualFocusSpeaker';

interface RoomPageProps {
  url: string;
  token: string;
  layout: string;
}

export default function RoomPage({ url, token, layout }: RoomPageProps) {
  const [error, setError] = useState<Error>();
  if (!url || !token) {
    return <div className="error">missing required params url and token</div>;
  }

  return (
    <LiveKitRoom serverUrl={url} token={token} onError={setError}>
      {error ? <div className="error">{error.message}</div> : <CompositeTemplate layout={layout} />}
    </LiveKitRoom>
  );
}

const marqueeContainerStyle: React.CSSProperties = {
  backgroundColor: '#8e4ec6',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  width: '100%',
  height: "50px",
  color:"white",
  position: 'relative', // Ensure proper positioning
};

const marqueeStyle: React.CSSProperties = {
  display: 'inline-block',
  animation: 'marquee 10s linear infinite',
  paddingLeft: '100%', // Start off-screen
};

const textStyle: React.CSSProperties = {
  display: 'inline-block',
  padding: '15px', 
  fontSize: "20px",
  fontWeight: "bold",
  whiteSpace: 'nowrap',
};


interface CompositeTemplateProps {
  layout: string;
}

function CompositeTemplate({ layout: initialLayout }: CompositeTemplateProps) {
  const room = useRoomContext();
  const [layout, setLayout] = useState(initialLayout);
  const [hasScreenShare, setHasScreenShare] = useState(false);
  const screenshareTracks = useTracks([Track.Source.ScreenShare], {
    onlySubscribed: true,
  });
  const [focusId, setFocusId] = useState("")

  useEffect(() => {
    if (room) {
      EgressHelper.setRoom(room);

      // Egress layout can change on the fly, we can react to the new layout
      // here.
      EgressHelper.onLayoutChanged((newLayout) => {
        setLayout(newLayout);
      });

      // start recording when there's already a track published
      let hasTrack = false;
      for (const p of Array.from(room.remoteParticipants.values())) {
        if (p.trackPublications.size > 0) {
          hasTrack = true;
          break;
        }
      }

      if (hasTrack) {
        EgressHelper.startRecording();
      } else {
        room.once(RoomEvent.TrackSubscribed, () => EgressHelper.startRecording());
      }
    }
  }, [room]);

  useEffect(() => {
    if (screenshareTracks.length > 0 && screenshareTracks[0].publication) {
      setHasScreenShare(true);
    } else {
      setHasScreenShare(false);
    }
  }, [screenshareTracks]);

  const allTracks = useTracks(
    [Track.Source.Camera, Track.Source.ScreenShare, Track.Source.Unknown],
    {
      onlySubscribed: true,
    },
  );
  
  let filteredTracks = allTracks.filter(
    (tr) =>
      tr.publication.kind === Track.Kind.Video &&
      tr.participant.identity !== room.localParticipant.identity,
  );

  let interfaceStyle = 'dark';
  if (layout.endsWith('-light')) {
    interfaceStyle = 'light';
  }

  let containerClass = 'roomContainer';
  if (interfaceStyle) {
    containerClass += ` ${interfaceStyle}`;
  }

  // determine layout to use
  let main: ReactElement = <></>;
  let effectiveLayout = layout;
  if (hasScreenShare && layout.startsWith('grid')) {
    effectiveLayout = layout.replace('grid', 'speaker');
  }
  if (room.state !== ConnectionState.Disconnected) {
    if (effectiveLayout.startsWith('speaker')) {
      main = <SpeakerLayout tracks={filteredTracks} />;
    } else if (effectiveLayout.startsWith('single-speaker')) {
      main = <SingleSpeakerLayout tracks={filteredTracks} />;
    } else if (effectiveLayout.startsWith('manual-focus')) {
      main = <ManualFocusSpeaker tracks={filteredTracks.filter(
      (tr) =>
        tr.publication.kind === Track.Kind.Video &&
        tr.participant.identity == effectiveLayout.split('-').slice(2).join('-'),
    )} />;
    } else if (effectiveLayout.startsWith('side-by-side')) {
      main = (
        <CustomGridLayout tracks={filteredTracks}>
          <ParticipantTile />
        </CustomGridLayout>
      );
    } else {
      main = (
        <GridLayout tracks={filteredTracks}>
          <ParticipantTile />
        </GridLayout>
      );
    }
  }

  return (
    <div className={containerClass}>
      <style>
                {`
                    @keyframes marquee {
                        0% { transform: translateX(0%); }
                        100% { transform: translateX(-100%); }
                    }
                `}
            </style>
            <div style={marqueeContainerStyle}>
                <div style={marqueeStyle}>
                    <div style={textStyle}>
                        WiseUP
                    </div>
                    <div style={textStyle}>
                        <Timer />
                    </div>
                </div>
            </div>
      {main}
      <RoomAudioRenderer />
    </div>
  );
}
