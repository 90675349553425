import { TrackReference, useVisualStableUpdate, VideoTrack } from '@livekit/components-react';
import { LayoutProps } from './common';

const ManualFocusSpeaker = ({ tracks: references }: LayoutProps) => {
  const sortedReferences = useVisualStableUpdate(references, 1);
  if (sortedReferences.length === 0) {
    return null;
  }
  return <>
    <VideoTrack trackRef={sortedReferences[0] as TrackReference} /> 
  </>
};

export default ManualFocusSpeaker;
