import React, { useEffect, useState, useRef } from 'react';
import { selectGridLayout, GridLayoutDefinition } from '@livekit/components-core'; 
import { TrackLoop, useSwipe } from '@livekit/components-react';

const CUSTOM_GRID_LAYOUTS: GridLayoutDefinition[] = [
  {
    columns: 1,
    rows: 1,
    minWidth: 200,
  },
  {
    columns: 2,
    rows: 1,
    minWidth: 400,
  },
  {
    columns: 3,
    rows: 1,
    minWidth: 600,
  },
  {
    columns: 4,
    rows: 1,
    minWidth: 800,
  },
  {
    columns: 5,
    rows: 1,
    minWidth: 1000,
  },
  {
    columns: 6,
    rows: 1,
    minWidth: 1200,
  },
  {
    columns: 7,
    rows: 1,
    minWidth: 1400,
  },
  {
    columns: 8,
    rows: 1,
    minWidth: 1600,
  },
  // Add more if needed
];

export function CustomGridLayout({ tracks, ...props }:any) {
  const gridEl = useRef<HTMLDivElement>(null);
  const [layout, setLayout] = useState({ columns: 1, rows: 1, maxTiles: 1 });

  useEffect(() => {
    if (gridEl.current) {
      const { width, height } = gridEl.current.getBoundingClientRect();
      const newLayout = selectGridLayout(CUSTOM_GRID_LAYOUTS, tracks.length, width, height);
      setLayout(newLayout);
    }
  }, [tracks.length]);

  useSwipe(gridEl, {
    onLeftSwipe: () => console.log('Swiped left'),
    onRightSwipe: () => console.log('Swiped right'),
  });

  return (
    <div
  ref={gridEl}
  className="custom-grid-layout"
  style={{
    display: 'grid',
    gridTemplateColumns: `repeat(${layout.columns}, 1fr)`,
    gridTemplateRows: `1fr`,
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  }}
  {...props}
>
      <TrackLoop tracks={tracks}>{props.children}</TrackLoop>
    </div>
  );
}
