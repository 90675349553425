import { useEffect, useState } from "react"

export default function Timer() {
  const [text, setText] = useState((new Date()).toUTCString());
  useEffect(() => {
    setInterval(() => {
      setText((new Date()).toUTCString());
    }, 1000)
  }, [])
  return <>{text}</>
}